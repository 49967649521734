import React, { useEffect, useState } from 'react'
import ResponsiveAppBar from './app-bar';
import SocialBar from '../components/social-bar';

function MyAssets() {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            const width = window.innerWidth;
            setWindowWidth(width);
        }

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () =>
            window.removeEventListener("resize", updateDimensions);
    }, []);

    const isMobile = () => {
        return width < 1024;
    }

    return (
        <div className='App'>
            <ResponsiveAppBar isMobile={isMobile()} isLandMarket={false} />
            <SocialBar></SocialBar>
            <div className='my-asset-content'>
                <div className={isMobile() ? '' : 'center-container block-box'} style={{ padding: "24px 12px" }}>
                    <h3 style={{textAlign: "center"}}>WELCOME TO YOUR MY ASSETS PAGE!</h3>
                    <div class="view">
                        <div class="plane main">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                        </div>
                    </div>
                    <div className='message-content'>This is where you can view and manage all your unique and valuable assets in the immersive world of Detherverse.
                        Your assets include the NFTs you have collected, acquired, or created, as well as the DVT tokens you own.
                        NFT minting and DVT token presale will be opening soon.
                        This is your chance to be among the first to own and experience unique NFTs and acquire DVT tokens that will empower you within the Detherverse ecosystem.
                        If you have any questions or need assistance, don't hesitate to reach out to our support team. Enjoy the journey and make the most of your Detherverse experience!
                    </div>
                </div>
            </div>
        </div>
    );
}
export default MyAssets;