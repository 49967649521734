import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { FitScreen, ZoomIn, ZoomOut } from '@mui/icons-material';

function ZoomInOut(props) {
    
    return (
        <Box
            sx={{
                display: 'flex',
                position: 'fixed',
                top: "80px",
                right: "0px",
                '& > *': {
                    m: 1,
                },
                zIndex: "99"
            }}
        >
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
            >
                <Button key="zoom-in" className='map-control-button' onClick={() => props.zoomIn()}><ZoomIn></ZoomIn></Button>
                <Button key="zoom-out" className='map-control-button' onClick={() => props.zoomOut()}><ZoomOut></ZoomOut></Button>
                <Button key="fit" className='map-control-button' onClick={() => props.fit()}><FitScreen></FitScreen></Button>
            </ButtonGroup>
        </Box>
    );
}

export default ZoomInOut;