import React, { useState } from 'react'
import { Avatar, Button, ButtonGroup, Card, CardActions, CardHeader, Input } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { AccountBalanceWalletOutlined, AddCircleOutline, RemoveCircleOutline, ShoppingBasketOutlined } from '@mui/icons-material';

// function NftMinCard({ image, title, content, handleClick }) {
function LandNFTCard(props) {
    return (
        <Card className='nft-card block-box'>
            <CardHeader
                title={
                    <h3>{props.selectedLandCell.land_name.toUpperCase()} {"(Location " + props.selectedLandCell.location.x + " X " + props.selectedLandCell.location.y + ")"}</h3>
                }
                sx={{ color: "white", flexDirection: "row-reverse" }}
            />
            <CardMedia
                component="img"
                alt="nft image"
                image={props.image}
                sx={{ borderRadius: "12px", width: "calc(100% - 16px)", margin: "auto" }}
            />
            <CardContent>
                <h3>Owner: N/A</h3>
                {Object.entries(props.selectedLandCell.terrain).map(([terrain, percentage], index) => (
                    <li key={index} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                        <span style={{ float: "left" }}>{terrain.toUpperCase()}</span>
                        <span style={{ float: "right" }}>{(percentage * 100).toFixed(3) + "%"}</span>
                    </li>
                ))}
            </CardContent>
            <CardActions>
                <Button disabled variant={props.isWalletConnected ? "contained" : "outlined"} startIcon={<AccountBalanceWalletOutlined />} onClick={() => props.handleClick({ quantity: 1, tier: props.tier })} sx={{ width: "100%" }}>MINT</Button>
            </CardActions>
        </Card>
    );
}
export default LandNFTCard;