import { Button, Fade, Tooltip, Typography, Zoom, styled, toolbarClasses, tooltipClasses } from '@mui/material';
import React, { } from 'react'

// function NftMinCard({ image, title, content, handleClick }) {
function LandCell(props) {
    // const HtmlTooltip = styled(({ className, ...props }) => (
    //     <Tooltip {...props} classes={{ popper: className }} />
    // ))(({ theme }) => ({
    //     [`& .${tooltipClasses.arrow}`]: {
    //         color: '#f5f5f9',
    //     },
    //     [`& .${tooltipClasses.tooltip}`]: {
    //         backgroundColor: '#f5f5f9',
    //         color: 'rgba(0, 0, 0, 0.87)',
    //         maxWidth: 220,
    //         fontSize: theme.typography.pxToRem(12),
    //         border: '1px solid #dadde9',
    //         borderRadius: "12px",
    //         padding: "12px"
    //     },
    // }));

    const handleLandClick = (event) => {
        const buttonPosition = {
            x: event.clientX,
            y: event.clientY,
        };
        
        props.handClick(props.landDetail, buttonPosition);
    }

    return (
        <div>
            {
                // props.landDetail != null ? <HtmlTooltip title={
                //     <React.Fragment>
                //         <img src='https://storage.googleapis.com/land-nft-epics/public/1.jpg' className='land-nft-epic'></img>
                //         <Typography color="inherit">{props.landDetail.land_name}</Typography>
                //         <h3>Location: {JSON.stringify(props.landDetail.location)}</h3>
                //         <h3>Terrain: {props.landDetail.terrain}</h3>
                //         <Button variant="contained" sx={{ width: "100%" }} onClick={() => props.handClick()}>MINT</Button>
                //         {/* <p>Environment: {props.landDetail.environment}</p>
                //         <p>Land Category: {props.landDetail.land_category}</p> */}
                //     </React.Fragment>
                // } arrow
                //     TransitionComponent={Zoom}
                //     TransitionProps={{ timeout: 600 }}
                // >
                //     <div className={'land-cell'} style={props.sx}></div>
                // </HtmlTooltip>
                //     : <div className={'water-cell'} style={props.sx}></div>
                props.landDetail != null ?
                    <button className={'land-cell'} style={props.sx} onClick={(event) => handleLandClick(event)}></button>
                    : <button className={'water-cell'} style={props.sx}></button>
            }
        </div>

    );
}
export default LandCell;