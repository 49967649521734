import * as React from 'react';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

function GoToTop(props) {

    return (
        <div className='to-top'>
            <Button className='to-top-button' onClick={() => { props.toTop() }}><ArrowDropUpIcon></ArrowDropUpIcon></Button>
        </div>
    );
}

export default GoToTop;