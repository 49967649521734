import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import TwitterIcon from '../assets/twitter-icon.png';
import DiscordIcon from '../assets/discord-icon.png';

function SocialBar() {
    return (
        <Box
            sx={{
                display: 'flex',
                position: 'fixed',
                bottom: "50%",
                right: "0px",
                '& > *': {
                    m: 1,
                },
                zIndex: "99",
                background: "#425998",
                borderRadius: "12px 0px 0px 12px",
            }}

            className='button-pulse'
        >
            <ButtonGroup
                orientation="vertical"
                aria-label="vertical outlined button group"
                sx={{ margin: "4px" }}
            >
                <Button key="zoom-in" className='social-control-button' onClick={() => { window.open("https://twitter.com/detherverse", "_blank"); }}><img src={TwitterIcon} alt='twitter' className='social-icon'></img></Button>
                <Button key="zoom-out" className='social-control-button' onClick={() => { window.open("https://discord.gg/cP5cyaeY5e", "_blank"); }}><img src={DiscordIcon} alt='discord' className='social-icon'></img></Button>
            </ButtonGroup>
        </Box>
    );
}

export default SocialBar;