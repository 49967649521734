import '../App.css';
import { useEffect, useRef, useState } from 'react';

import { Button, Grid, Modal, Pagination } from '@mui/material';
import ResponsiveAppBar from './app-bar';
import LandCell from '../components/land-cell';
import LandData from '../assets/lands.json';
import ZoomInOut from '../components/zoom-in-out';
import Draggable from 'react-draggable';
import ResponsiveSideBar from './side-bar';
import LandNFTCard from '../components/land-nft-card';
import { AccountBalanceWalletOutlined } from '@mui/icons-material';
import SocialBar from '../components/social-bar';
import GoToTop from '../components/go-to-top';

function LandMarketPlace() {
    const [width, setWindowWidth] = useState(0);
    const [mapCellWidth, setMapCellWidth] = useState(0);
    const [mapCellHeight, setMapCellHeight] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
    const [selectedLandCell, setSelectedLandCell] = useState(null);
    const [pageLoaded, setPageLoaded] = useState(false);
    const [nftImageLoaded, setNftImageLoaded] = useState(false);
    const [isMapViewMode, setIsMapViewMode] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [x, setX] = useState(0);
    const [y, setY] = useState(0);

    const landsRef = useRef(null);

    useEffect(() => {
        const updateDimensions = () => {
            const width = window.innerWidth;
            setWindowWidth(width);
        }

        if (window.innerWidth >= window.innerHeight) {
            setMapCellWidth((window.innerHeight - 80) / 100.0);
            setMapCellHeight((window.innerHeight - 80) / 100.0);
        } else {
            setMapCellWidth(window.innerWidth / 100.0);
            setMapCellHeight(window.innerWidth / 100.0);
        }

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        setTimeout(() => {
            setPageLoaded(true);
        }, 1000);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        }
    }, []);

    const isMobile = () => {
        return width < 1024;
    }

    const zoomIn = () => {
        setMapCellWidth(prevCellWidth => (prevCellWidth * 1.2));
        setMapCellHeight(prevCellHeight => (prevCellHeight * 1.2));
    }

    const zoomOut = () => {
        setMapCellWidth(prevCellWidth => (prevCellWidth / 1.2));
        setMapCellHeight(prevCellHeight => (prevCellHeight / 1.2));
    }

    const fitMap = () => {
        if (window.innerWidth >= window.innerHeight) {
            const size = (window.innerHeight - 80) / 100.0;
            setMapCellWidth(size);
            setMapCellHeight(size);
        } else {
            setMapCellWidth(window.innerWidth / 100.0);
            setMapCellHeight(window.innerWidth / 100.0);
        }

        setX(0);
        setY(0);
    }

    const handleDragStop = (event, dragElement) => {
        setX(dragElement.x)
        setY(dragElement.y)
    };

    function generateLandColors(terrainPercentages) {
        if (terrainPercentages === "sea") {
            return "#4DB7FE";
        }
        const terrainTypes = Object.keys(terrainPercentages);

        // Calculate the total percentage
        const totalPercentage = Object.values(terrainPercentages).reduce((sum, percentage) => sum + percentage, 0);

        const colors = {};

        // Calculate colors based on terrain percentages
        for (const terrain of terrainTypes) {
            const percentage = terrainPercentages[terrain];
            const brightness = percentage / totalPercentage;
            const hue = getHueForTerrain(terrain);
            const saturation = 100;
            const lightness = 50 + brightness * 50; // Adjust the lightness based on the terrain percentage
            colors[terrain] = `hsl(${hue}, ${saturation}%, ${lightness}%)`;
        }

        let largestKey = null;
        let largestPercentage = -1;

        for (const terrain in terrainPercentages) {
            const percentage = terrainPercentages[terrain];
            if (percentage > largestPercentage) {
                largestPercentage = percentage;
                largestKey = terrain;
            }
        }

        return colors[largestKey];
    }

    function getHueForTerrain(terrain) {
        // Assign specific hues for each terrain type (modify as per your preferences)
        switch (terrain) {
            case "forest":
                return 100; // Green hue
            case "mountains":
                return 240; // Blue hue
            case "plains":
                return 60; // Yellow hue
            case "river":
                return 210; // Cyan hue
            case "desert":
                return 40; // Orange hue
            case "beach":
                return 40; // Orange hue (same as desert for this example)
            default:
                return 0; // Default to red hue if the terrain is not recognized
        }
    }

    const handleClickLandCell = (landDetail, buttonPosition) => {
        setSelectedLandCell(landDetail);
        if (isMobile()) {
            buttonPosition.x = 12;
            buttonPosition.y = 92;
        } else {
            if (buttonPosition.y > window.innerHeight / 2) {
                buttonPosition.y -= 465.0;
            }

            if (buttonPosition.x > window.innerWidth / 2) {
                buttonPosition.x -= 240;
            }
        }

        setClickPosition(buttonPosition);

        setModalOpen(true);
    }

    const handleClickLandCard = (value) => {
        console.log(value);
    }

    const handleSideBarChanged = (value) => {
        if (value === 'map-view') {
            setIsMapViewMode(true);
        } else if (value === 'list-view') {
            setIsMapViewMode(false);
        }
    }

    const paginationChanged = (event, page) => {
        setCurrentPage(page - 1);
    }

    const handleCloseModal = () => {
        setNftImageLoaded(false);
        setModalOpen(false);
    };

    const getLandImageUrl = (landId) => {
        return 'https://storage.googleapis.com/land-nft-epics/public/' + (landId % 1000 + 1) + '.jpg';
    }

    const renderMapView = () => {
        return (
            <div className="land-map">
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    style={{
                        position: 'absolute',
                        top: clickPosition.y,
                        left: clickPosition.x,
                    }}
                >
                    <div className="modal-content">
                        {selectedLandCell && <div>
                            <div className="loader-wrap" style={nftImageLoaded ? { display: 'none' } : {}}>
                                <div className="loader-inner">
                                    <div className="loader-inner-cirle"></div>
                                </div>
                            </div>
                            <img style={nftImageLoaded ? {} : { display: 'none' }} onLoad={() => setNftImageLoaded(true)} alt="nft epic" src={getLandImageUrl(selectedLandCell.land_id)} className='land-nft-epic'></img>
                            <h3>{selectedLandCell.land_name.toUpperCase()} {"(Location " + selectedLandCell.location.x + " X " + selectedLandCell.location.y + ")"}</h3>
                            <h3>Owner: N/A</h3>
                            {Object.entries(selectedLandCell.terrain).map(([terrain, percentage], index) => (
                                <li key={index} style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                    <span style={{ float: "left" }}>{terrain.toUpperCase()}</span>
                                    <span style={{ float: "right" }}>{(percentage * 100).toFixed(3) + "%"}</span>
                                </li>
                            ))}
                            <Button disabled variant="contained" sx={{ width: "100%" }} startIcon={<AccountBalanceWalletOutlined />}>MINT</Button>
                        </div>}
                    </div>
                </Modal>
                <Grid container>
                    {
                        LandData.map((row, rowIndex) =>
                            <Grid key={rowIndex} container item sx={{ flexWrap: "nowrap", WebkitFlexWrap: "nowrap" }}>
                                {
                                    row.map((cell, colIndex) =>
                                        <Grid key={colIndex} item>
                                            <LandCell landDetail={cell} handClick={(landDetail, buttonPosition) => { handleClickLandCell(landDetail, buttonPosition) }} sx={{ width: mapCellWidth, height: mapCellHeight, background: generateLandColors(cell == null ? "sea" : cell.terrain) }}></LandCell>
                                        </Grid>
                                    )
                                }
                            </Grid>
                        )
                    }
                </Grid>
            </div>
        );
    }

    return (
        <div>
            {
                pageLoaded === false ? <div className="page-loader-wrap">
                    <div className="loader-inner">
                        <div className="loader-inner-cirle"></div>
                    </div>
                </div> : <div className='App'>
                    <ResponsiveAppBar isMobile={isMobile()} isLandMarket={true} onChange={(value) => { handleSideBarChanged(value) }} isMapViewMode={isMapViewMode} />
                    <SocialBar></SocialBar>
                    {isMapViewMode ? <ZoomInOut zoomIn={() => { zoomIn() }} zoomOut={() => { zoomOut() }} fit={() => { fitMap() }}></ZoomInOut>
                        : <GoToTop toTop={() => { landsRef.current.scrollIntoView({ behavior: 'smooth' }) }}></GoToTop>}
                    <div className='market-place-content'>
                        <Grid container sx={{ height: "100%" }}>
                            {!isMobile() && <Grid item xl={2} md={3} xs={12} sx={{ zIndex: 2, height: "100%", overflow: "auto" }}>
                                <ResponsiveSideBar isMobile={isMobile()} onChange={(value) => { handleSideBarChanged(value) }} isMapViewMode={isMapViewMode} />
                            </Grid>}
                            <Grid item xl={10} md={9} xs={12} sx={{ height: "100%", padding: isMapViewMode ? "0px" : "12px", overflow: "auto" }}>
                                {!isMapViewMode && <Pagination className='pagination' size={isMobile() ? "small" : ""} count={100} variant="outlined" shape="rounded" onChange={(event, page) => { paginationChanged(event, page) }} />}
                                {
                                    isMapViewMode ?
                                        isMobile() ? renderMapView() : <Draggable sx={{ zIndex: 1 }} onStop={handleDragStop} position={{ x: x, y: y }}>
                                            {renderMapView()}
                                        </Draggable>
                                        : <div ref={landsRef}>
                                            <Grid container spacing={2} sx={{ marginBottom: "80px" }}>
                                                {
                                                    LandData[currentPage].map((cell, index) =>
                                                        cell != null &&
                                                        <Grid key={index} item xs={12} sm={4} md={3} lg={2}>
                                                            <LandNFTCard image={getLandImageUrl(cell.land_id)} handleClick={(value) => { handleClickLandCard(value) }} selectedLandCell={cell} isWalletConnected={false}></LandNFTCard>
                                                        </Grid>
                                                    )
                                                }
                                            </Grid>
                                        </div>

                                }

                            </Grid>
                        </Grid>
                    </div>
                </div>
            }
        </div>
    );
}

export default LandMarketPlace;
