import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button';

import MenuIcon from '../assets/menu.svg';
import { Drawer } from '@mui/material';
import LogoImage from '../assets/logo.png';
import ResponsiveSideBar from './side-bar';
import { CloseRounded } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum'
import { Web3Button, Web3Modal } from '@web3modal/react'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { arbitrum, mainnet, polygon } from 'wagmi/chains'

const chains = [arbitrum, mainnet, polygon]
const projectId = '9deb8ca495a34103c48822489510e96d'

const { publicClient } = configureChains(chains, [w3mProvider({ projectId })])
const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
})
const ethereumClient = new EthereumClient(wagmiConfig, chains)

function ResponsiveAppBar(props) {
    const navigate = useNavigate();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    useEffect(() => {

    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
    };

    const goToLandMarketplace = () => {
        navigate('/', {
        });
    };

    const goToBuyToken = () => {
        navigate('/dvt-token', {
        });
    }

    const goToMyAssets = () => {
        navigate('/my-assets', {
        });
    }

    return (
        <>
            <WagmiConfig config={wagmiConfig}>
                <div className='app-header'>
                    {
                        props.isMobile ?
                            <div className='main-header'>
                                <Button className='nav-menu-button' onClick={toggleDrawer(true)}>
                                    <img src={MenuIcon} alt='Menu'></img>
                                </Button>
                                {/* <Button className='nav-right-button' onClick={() => { }}>CONNECT WALLET</Button> */}
                                <div className='wallet-connect-button'><Web3Button></Web3Button></div>
                            </div> :
                            <div className='main-header'>
                                <img className='nav-button' src={LogoImage} alt='logo' onClick={() => { window.open("https://detherverse.com/") }}></img>
                                <div className='nav-holder'>
                                    <Button className='nav-button' onClick={() => { goToLandMarketplace() }}>Lands</Button>
                                    <Button className='nav-button' onClick={() => { goToBuyToken() }}>DVT Token</Button>
                                    <Button className='nav-button' onClick={() => { goToMyAssets() }}>My Assets</Button>
                                </div>
                                {/* <Button className='nav-right-button' onClick={() => { }}>CONNECT WALLET</Button> */}
                                <div className='wallet-connect-button'><Web3Button></Web3Button></div>
                            </div>
                    }

                    <Drawer
                        anchor={'left'}
                        open={isDrawerOpen}
                        onClose={toggleDrawer(false)}
                        PaperProps={{
                            sx: {
                                backgroundColor: "rgba(0, 0, 0, 0.7)",
                                width: "100%",
                            }
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between" }}><img className='app-bar-logo' src={LogoImage} alt='logo' onClick={() => { window.open("https://detherverse.com/") }}></img>
                            <Button variant="contained" className='app-bar-close' onClick={() => { setIsDrawerOpen(false) }}><CloseRounded></CloseRounded></Button></div>
                        <Button className='nav-mobile-button' onClick={() => { goToLandMarketplace() }}>Lands</Button>
                        <Button className='nav-mobile-button' onClick={() => { goToBuyToken() }}>DVT Token</Button>
                        <Button className='nav-mobile-button' onClick={() => { goToMyAssets() }}>My Assets</Button>
                        {props.isLandMarket && <ResponsiveSideBar onChange={(value) => { props.onChange(value) }} isMapViewMode={props.isMapViewMode}></ResponsiveSideBar>}
                    </Drawer>
                </div>
            </WagmiConfig>

            <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
        </>

    );
}
export default ResponsiveAppBar;