import './App.css';
import './animation.css'
import './fonts/Bobby_Jones_Soft.otf'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandMarketPlace from './pages/land-market-place';
import MyAssets from './pages/my-assets';
import BuyToken from './pages/dvt-token';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandMarketPlace />} />
        <Route path="/dvt-token" element={<BuyToken />} />
        <Route path="/my-assets" element={<MyAssets />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
