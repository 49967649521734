import React, { useEffect, useState } from 'react'
import ResponsiveAppBar from './app-bar';
import SocialBar from '../components/social-bar';

function BuyToken() {
    const [width, setWindowWidth] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            const width = window.innerWidth;
            setWindowWidth(width);
        }

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        return () =>
            window.removeEventListener("resize", updateDimensions);
    }, []);

    const isMobile = () => {
        return width < 1024;
    }

    return (
        <div className='App'>
            <ResponsiveAppBar isMobile={isMobile()} isLandMarket={false} />
            <SocialBar></SocialBar>
            <div className='dvt-token-content'>
                <div className={isMobile() ? '' : 'center-container block-box'} style={{ padding: "24px 12px" }}>
                    <h3 style={{ textAlign: "center" }}>WELCOME TO YOUR BUY TOKEN PAGE!</h3>
                    <div class="view">
                        <div class="plane main">
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                            <div class="circle"></div>
                        </div>
                    </div>
                    <div className='message-content'>
                        DVT token presale is fast approaching! Get ready to secure your place in the future of decentralized gaming and virtual reality.
                        During the presale, you'll have the exclusive opportunity to be among the first to own DVT tokens, granting you access to a wide array of benefits within our immersive ecosystem.
                        Stay tuned for the official start date and further details, as the excitement builds towards this groundbreaking event.
                        Prepare to embark on an unforgettable journey in Detherverse, where dreams become reality.
                    </div>
                    <h3 style={{ textAlign: "center" }}>See you at the DVT token presale!</h3>
                </div>
            </div>
        </div>
    );
}
export default BuyToken;