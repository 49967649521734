import React, { useEffect, useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, FormControlLabel, Grid, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, Slider } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

function ResponsiveSideBar(props) {

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [priceValue, setPriceValue] = useState([1, 100]);

    const handleChange = (event, newValue) => {
        setPriceValue(newValue);
    };


    useEffect(() => {

    }, []);

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setIsDrawerOpen(open);
    };

    function valuetext(value) {
        return `${value} DVT`;
    }

    return (
        <div className='side-bar'>
            <Accordion defaultExpanded={true} square={true} className='block-box'>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <h3>Status</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="all-land"
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="all-land" control={<Radio />} label="All Land" className='land-status' />
                        <FormControlLabel value="available-mint" control={<Radio />} label="Available to mint" className='land-status' />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} square={true} className='block-box'>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3>Price</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="filled-adornment-amount" sx={{ textAlign: "left" }} className='land-status'>MIN</InputLabel>
                            <OutlinedInput
                                id="filled-adornment-amount"
                                value={priceValue[0]}
                                startAdornment={<InputAdornment position="start">DVT</InputAdornment>}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel htmlFor="filled-adornment-amount" sx={{ textAlign: "left" }} className='land-status'>MAX</InputLabel>
                            <OutlinedInput
                                id="filled-adornment-amount"
                                value={priceValue[1]}
                                startAdornment={<InputAdornment position="start">DVT</InputAdornment>}
                            />
                        </Grid>
                    </Grid>
                    <Slider
                        getAriaLabel={() => 'Price Range'}
                        value={priceValue}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        getAriaValueText={valuetext}
                        disableSwap
                    />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded={true} square={true} className='block-box'>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                >
                    <h3>View Mode</h3>
                </AccordionSummary>
                <AccordionDetails>
                    <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={props.isMapViewMode ? "map-view" : "list-view"}
                        name="radio-buttons-group"
                        onChange={(e, value) => props.onChange(value)}
                    >
                        <FormControlLabel value="map-view" control={<Radio />} label="Map View" className='land-status' />
                        <FormControlLabel value="list-view" control={<Radio />} label="List View" className='land-status' />
                    </RadioGroup>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
export default ResponsiveSideBar;